import 'vuetify/styles';
import { createVuetify } from 'vuetify';

const lightTheme = {
  dark: false,
  colors: {
    primary: '#007FA2', // dusk-500
    error: '#E51E17',
    success: '#008841',
    info: '#008BC3', // dusk-450
    warning: '#FFD12F',
    brand: '#EE7123',
    'surface-variant': '#76797A', // earl-500
    'on-surface-variant': '#F2F3F4', // white
    'default-btn-color': '#434648', // earl-700
    'medium-priority': '#F58220',
    'info-500': '#4A4A4A',
    'success-500': '#2DB960',
    'selected-table-header': '#080809', // earl-1000
    'internal-note-bg': '#FFF2CE', // amaizeing-100
    'comment-bg': '#F2F3F4', // white
    'earl-200': '#DCDDDE',
    'earl-300': '#BABCBD',
    'earl-500': '#76797A',
    'earl-600': '#54585A',
    'earl-700': '#434648',
    'earl-900': '#212324',
    'earl-1000': '#080809',
    'amaizeing-100': '#FFF2CE',
  },
};

const darkTheme = {
  dark: true,
  colors: {
    primary: '#79C1D7', // dusk-200
    secondary: '#3F51B5',
    accent: '#2196F3',
    error: '#F68D8A',
    success: '#78FFB9',
    warning: '#FFE68C',
    info: '#A5D6E4',
    anchor: '#2196F3',
    brand: '#FFD8A3',
    'surface-variant': '#BABCBD', // earl-300
    'on-surface-variant': '#F6F7F8', // earl-50
    'default-btn-color': '#F2F3F4', // white
    'medium-priority': '#FFD8A3',
    'success-500': '#2DB960',
    'selected-table-header': '#f6f7f8', // earl-50
    'internal-note-bg': '#584F33',
    'comment-bg': '#212324', // earl-900
    'earl-900': '#212324',
  },
};

const opts = {
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
      darkTheme,
    },
  },
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  defaults: {
    VAlert: {
      density: 'compact',
    },
    VAutocomplete: {
      density: 'compact',
      variant: 'outlined',
    },
    VBtn: {
      density: 'comfortable',
      variant: 'text',
      color: 'default-btn-color',
    },
    VBtnToggle: {
      color: 'primary',
      density: 'compact',
    },
    VCheckbox: {
      color: 'primary',
      density: 'compact',
    },
    VCard: {
      variant: 'outlined',
    },
    VCardSubtitle: {
      class: 'tw-opacity-100',
    },
    VChip: {
      class: 'tw-border-earl-300 dark:tw-border-white',
      variant: 'outlined',
    },
    VContainer: {
      variant: 'fluid',
    },
    VDatePicker: {
      color: 'primary',
    },
    VInput: {
      density: 'compact',
    },
    VList: {
      color: 'primary',
      density: 'compact',
    },
    VListItem: {
      color: 'primary',
      density: 'compact',
      class: 'tw-font-medium',
    },
    VListItemTitle: {
      class: 'tw-text-sm tw-font-medium',
    },
    VListItemSubtitle: {
      class: 'tw-text-xs tw-font-medium tw-text-earl-700 dark:tw-text-earl-50',
    },
    VMenu: {
      location: 'bottom right',
      openDelay: '200',
    },
    VRadio: {
      color: 'primary',
      density: 'compact',
    },
    VRadioGroup: {
      color: 'primary',
      density: 'compact',
    },
    VSelect: {
      itemColor: 'primary',
      variant: 'outlined',
      density: 'compact',
      color: 'primary',
    },
    VSwitch: {
      density: 'compact',
      color: 'success-500',
      flat: true,
      hideDetails: true,
      ripple: false,
    },
    VTabs: {
      color: 'primary',
      sliderColor: 'primary',
    },
    VTextarea: {
      color: 'primary',
      variant: 'outlined',
      density: 'compact',
    },
    VTextField: {
      color: 'primary',
      variant: 'outlined',
      density: 'compact',
      clearIcon: 'mdi-close',
    },
    VTooltip: {
      location: 'bottom',
      openDelay: '200',
    },
  },
};

const vuetify = createVuetify(opts);

export default vuetify;
